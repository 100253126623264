import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { SimpleCenteredModal } from '../../../components';
import InsurancePolicyDebtContract from './InsurancePolicyDebtContract';
import BeneficiariesAddition from './BeneficiariesAddition';
import BeneficiariesExclusion from './BeneficiariesExclusion';

const BulkUploadIndex = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalSize, setModalSize] = useState('md');
  const [activeModal, setActiveModal] = useState(null);

  const handleModalClose = () => {
    setModalShow(false);
    setTimeout(() => {
      setModalTitle('');
      setModalBody('');
      setErrorMessage('');
    }, 300);
  };

  const handleModalOpen = ({ body, title, size = 'md', activeModalName }) => {
    setModalShow(true);
    setModalTitle(title);
    setModalBody(body);
    setModalSize(size);
    setActiveModal(activeModalName);
  };

  return (
    <Row>
      <Col xs={12} className="mt-4 pt-2">
        <h5 className="text-primary-dark-blue">Carga masiva</h5>
      </Col>

      <InsurancePolicyDebtContract
        handleModalOpen={handleModalOpen}
        handleModalClose={handleModalClose}
        setModalBody={setModalBody}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        activeModal={activeModal}
      />

      <BeneficiariesAddition
        handleModalOpen={handleModalOpen}
        handleModalClose={handleModalClose}
        setModalBody={setModalBody}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        setModalSize={setModalSize}
        activeModal={activeModal}
      />

      <BeneficiariesExclusion
        handleModalOpen={handleModalOpen}
        handleModalClose={handleModalClose}
        setModalBody={setModalBody}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        setModalSize={setModalSize}
        activeModal={activeModal}
      />

      <SimpleCenteredModal
        size={modalSize}
        show={modalShow}
        title={modalTitle}
        body={modalBody}
        onHide={handleModalClose}
      />
    </Row>
  );
};

export default BulkUploadIndex;
