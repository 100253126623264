import React, { useEffect } from 'react';
import { getIn, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { useFetchForRemoteSelect } from '../../hooks';
import { debounceIndexAdminUsersRequest, indexAdminUserRequest } from '../../requests/admin/adminUsers';
import { debounceIndexUsersRequest, indexUserRequest } from '../../requests/user';
import { showAccountRequest } from '../../requests/accounts';
import { showAdminAccountRequest } from '../../requests/admin/adminAccounts';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { InputRemoteSelect } from '../Utils/Select';

const ExecutiveManagerRemoteSelector = ({
  allowUpdate,
  setAllowUpdate = () => {},
  disabled,
  fromAdmin,
  field,
  modelName,
  tooltipText
}) => {
  const { user: currentUser } = useSelector(state => state.auth);
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const { executiveManagerId, accountId } = getIn(values, modelName);
  const dispatch = useDispatch();

  const {
    options: executives,
    selectedOption: selectedExecutive,
    fetchOptions: fetchExecutives
  } = useFetchForRemoteSelect({
    indexRequest: fromAdmin ? indexAdminUserRequest : indexUserRequest,
    debouncedIndexRequest: fromAdmin ? debounceIndexAdminUsersRequest : debounceIndexUsersRequest,
    initialParams: { sortColumn: 'email', for_executives: 'present', admins: 'present', active: 'present' },
    value: executiveManagerId
  });

  const handleOnChange = data => {
    setFieldValue(field.name, data?.value || '');
  };

  const handleSuccessAccountRequest = response => {
    const account = camelCaseEmptyStringRecursive(response.data);
    if (account.executiveManagerId) {
      setFieldValue(`${modelName}[executiveManagerId]`, account.executiveManagerId);
    }
  };

  const handleAccountIdChange = () => {
    if (fromAdmin) {
      if (!executiveManagerId) setFieldValue(`${modelName}[executiveManagerId]`, currentUser.id);
      return;
    }

    if (accountId && accountId !== '' && allowUpdate) {
      const showRequest = fromAdmin ? showAdminAccountRequest : showAccountRequest;
      showRequest(accountId, {
        dispatch,
        successCallback: response => handleSuccessAccountRequest(response)
      });
    }
    if (!allowUpdate) {
      setAllowUpdate(true);
    }
  };

  useEffect(() => {
    if (fromAdmin && currentUser?.id) {
      if (!executiveManagerId) setFieldValue(`${modelName}[executiveManagerId]`, currentUser.id);
    }
  }, [fromAdmin, currentUser?.id, setFieldValue, modelName]);

  useEffect(handleAccountIdChange, [accountId]);

  return (
    <InputRemoteSelect
      abbr
      {...field}
      isClearable
      disabled={disabled}
      label="Ejecutivo"
      placeholder="Seleccionar ejecutivo"
      tooltipText={tooltipText}
      defaultOtions={executives}
      value={selectedExecutive}
      request={fetchExecutives}
      onChange={handleOnChange}
      error={getIn(errors, field.name)}
      touched={getIn(touched, field.name)}
    />
  );
};

export default ExecutiveManagerRemoteSelector;
