import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { BasicTextArea, FormikInput, FormikSwitch } from '../../../components';
import CompanyRemoteSelect from './CompanyRemoteSelect';
import InsuranceCategoryRemoteSelector from './InsuranceCategoryRemoteSelector';

const AdminAiParameterForm = ({ action, errors, onHide, submitVariant, touched }) => {
  const submitBtnText = action === 'new' ? 'Crear parámetro IA' : 'Guardar Cambios';

  return (
    <Form>
      <Row>
        <Col md={6} sm={12}>
          <Field name="aiParameter[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6} sm={12}>
          <Field name="aiParameter[active]">
            {({ field }) => <FormikSwitch {...field} field={field} label="¿Parámetro activo?" />}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <Field name="aiParameter[companyId]">
            {({ field }) => <CompanyRemoteSelect {...field} abbr field={field} modelName="aiParameter" />}
          </Field>
        </Col>
        <Col sm={12} md={6} lg={6}>
          <Field name="aiParameter[insuranceCategoryId]">
            {({ field }) => <InsuranceCategoryRemoteSelector abbr field={field} modelName="aiParameter" />}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <Field name="aiParameter[validateAiParams]">
            {({ field }) => (
              <BasicTextArea
                {...field}
                label="Validación IA"
                placeholder="Ingresa los puntos a validar"
                className="p-3"
                row={6}
              />
            )}
          </Field>
        </Col>
        <Col sm={12} md={6}>
          <Field name="aiParameter[compareAiParams]">
            {({ field }) => (
              <BasicTextArea
                {...field}
                label="Comparación IA"
                placeholder="Ingresa los puntos a comparar"
                className="p-3"
                row={6}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end">
        <Col md={3} sm={12} className="my-5">
          <Button type="submit" variant={submitVariant} size="lg" onClick={onHide} className="w-100">
            {submitBtnText}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ aiParameter }) => {
  return {
    aiParameter: {
      ...aiParameter
    }
  };
};

const validationSchema = Yup.object().shape({
  aiParameter: Yup.object().shape({
    active: Yup.boolean(),
    name: Yup.string().required('Debes ingresar el nombre'),
    insuranceCategoryId: Yup.string().required('Debes seleccionar un ramo'),
    companyId: Yup.string().required('Debes seleccionar una compañía')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest, onHide } = props;
  formRequest(values);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(AdminAiParameterForm);
