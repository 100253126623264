import React from 'react';

const StepInfo = ({ stepNumber, stepText, stepTextSecondary = '', customClassName = '' }) => (
  <div className={`step-info ${customClassName} ${stepTextSecondary ? 'client' : ''}`}>
    <div className="step-info--number">{stepNumber}</div>
    <h5 className="step-info--text font-weight-900 text-color-primary-dark">{stepText}</h5>
    {stepTextSecondary && (
      <p className="step-info--text-secondary font-weight-500 text-color-primary-dark">{stepTextSecondary}</p>
    )}
  </div>
);

export default StepInfo;
