import React, { useRef } from 'react';

import { DefaultHeader } from '../../../components';
import { useFetchData } from '../../../hooks';
import AdminAiParameterDataTable from './AdminAiParameterDataTable';
import { debounceIndexAdminAiParameterRequest } from '../../../requests/admin/adminAiParameters';

const AdminAiParameterIndex = () => {
  const customParamsRef = useRef({});
  const {
    data: aiParameters,
    totalData: totalAiParameters,
    moreData,
    setMoreData,
    isFetching,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminAiParameterRequest,
    fetchingErrorMessage: 'Ops, hubo un problema al buscar los parámetros IA. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current,
    withDataTable: true
  });

  const modelUrl = '/admin/ai_parameters';

  return (
    <DefaultHeader
      title="Parámetros IA"
      headerBtn
      headerBtnOptions={{
        icon: 'add',
        name: 'Nuevo parámetro IA',
        path: `${modelUrl}/new`,
        normalText: true
      }}
    >
      <AdminAiParameterDataTable
        customParamsRef={customParamsRef}
        handleIndexRequest={handleIndexRequest}
        isFetching={isFetching}
        modelUrl={modelUrl}
        moreData={moreData}
        setMoreData={setMoreData}
        tableData={aiParameters}
        tableDataAmount={totalAiParameters}
      />
    </DefaultHeader>
  );
};

export default AdminAiParameterIndex;
