const basicAiParameter = {
  id: '',
  name: '',
  validateAiParams: '',
  compareAiParams: '',
  insuranceCategoryId: '',
  companyId: '',
  active: true
};

export default basicAiParameter;
