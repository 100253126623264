import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import snakecaseKeys from 'snakecase-keys';

import { LoginCatchphrase, LoginNavbar } from '../../../components/Auth';
import LoginBox from './LoginBox';
import { requestSignIn, signInByVerificationCodeRequest } from '../../../actions/auth';
import sendVerificationCodeRequest from '../../../requests/signInByVerificationCodes';
import { sendAlert } from '../../../actions/utils';

const LoginIndex = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { from } = location.state || {};
  const [sentEmail, setSentEmail] = useState(false);

  const successCallback = result => {
    if (result.two_factor_enabled) setSentEmail(true);
  };

  const callback = setSubmitting => {
    setSubmitting(false);
  };

  const handleSignInRequest = (values, setSubmitting) => {
    dispatch(
      requestSignIn(
        {
          user: {
            email: values.email,
            password: values.password
          }
        },
        from,
        successCallback,
        callback(setSubmitting)
      )
    );
  };

  const handleSendVerificationCodeRequest = (values, setSubmitting) => {
    const sendParams = {
      user: { email: values.email }
    };
    sendVerificationCodeRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Código de verificación enviado con éxito' }));
        setSentEmail(true);
      },
      callback: () => setSubmitting(false)
    });
  };

  const handleSignInVerificationCodeRequest = (values, setSubmitting) => {
    const sendParams = snakecaseKeys({ user: values });
    dispatch(signInByVerificationCodeRequest(sendParams, setSubmitting, from, false));
  };

  return (
    <>
      <div className="login-index">
        <LoginNavbar showRegister />
        <LoginCatchphrase />
        <LoginBox
          fromLocation={from}
          formRequest={sentEmail ? handleSignInVerificationCodeRequest : handleSignInRequest}
          sentEmail={sentEmail}
          sendCodeRequest={handleSendVerificationCodeRequest}
        />
      </div>
      <div className="login-index--circles">
        <span className="circle circle-big" />
        <span className="circle white circle-petite-1" />
        <span className="circle white circle-petite-2" />
        <span className="circle white circle-petite-3" />
        <span className="circle white circle-bottom" />
        <span className="circle circle-colored" />
      </div>
    </>
  );
};

export default LoginIndex;
