import { useFetchForRemoteSelect } from '../../../hooks';
import { debounceIndexAiParameterRequest, indexAiParameterRequest } from '../../../requests/aiParameters';

const useAiParameters = (insuranceCategoryId, companyId) => {
  const { options: aiParameters, fetchOptions: fetchAiParameters } = useFetchForRemoteSelect({
    indexRequest: indexAiParameterRequest,
    debouncedIndexRequest: debounceIndexAiParameterRequest,
    initialParams: {
      for_selector: true,
      insurance_category_id: insuranceCategoryId || '',
      company_id: companyId || ''
    }
  });

  const validateAiParamsCount = aiParameters.filter(item => item.validate_ai_params).length;

  return { aiParameters, fetchAiParameters, validateAiParamsCount };
};

export default useAiParameters;
