import React from 'react';
// import { getIn, useFormikContext } from 'formik';
// import camelCaseRecursive from 'camelcase-keys-recursive';
import { InputRemoteSelect } from '../../Utils/Select';
import { useFetchForRemoteSelect } from '../../../hooks';
import { debounceIndexAiParameterRequest, indexAiParameterRequest } from '../../../requests/aiParameters';

const AiParameterSelect = ({ selectedInsuranceType, disabled, ...props }) => {
  const { selectedAiParameter, setSelectedAiParameter, insuranceCategoryId, companyId } = props;
  const { options: aiParameters, selectedOption, fetchOptions: fetchAiParameters } = useFetchForRemoteSelect({
    indexRequest: indexAiParameterRequest,
    debouncedIndexRequest: debounceIndexAiParameterRequest,
    value: selectedAiParameter?.value,
    initialParams: {
      for_selector: true,
      insurance_category_id: insuranceCategoryId || '',
      company_id: companyId || ''
    }
  });
  return (
    <InputRemoteSelect
      abbr
      isClearable
      label="Parámetro IA"
      placeholder="Parámetro IA"
      defaultOptions={aiParameters}
      value={selectedOption}
      request={fetchAiParameters}
      disabled={aiParameters.length === 0}
      onChange={data => {
        setSelectedAiParameter(data);
      }}
    />
  );
};
export default AiParameterSelect;
