const basicContractItem = {
  id: '',
  insuranceItemId: '',
  insurancePolicyId: '',
  itemNumber: '',
  name: '',
  notes: '',
  title: '',
  status: 'active'
};

export default basicContractItem;
