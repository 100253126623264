import React from 'react';

import { ComponentDataTable } from '..';

const BeneficiaryMovementDatatable = ({
  columns,
  handleIndexRequest,
  isFetching,
  moreData,
  noSubHeader,
  tableData,
  tableDataAmount
}) => {
  return (
    <>
      <ComponentDataTable
        onRequest={isFetching}
        columns={columns}
        data={tableData}
        totalRows={tableDataAmount}
        moreData={moreData}
        highlightOnHover
        customDatatableHeader={<></>}
        pointerOnHover
        resourceRequest={handleIndexRequest}
        noSubHeader={noSubHeader}
        // defaultParams={{ sortColumn: 'asc', ...defaultParams }}
        defaultParams={{}}
      />
    </>
  );
};

export default BeneficiaryMovementDatatable;
