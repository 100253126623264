import React from 'react';

import IconGG from '../Utils/Icon';

const InfoBox = ({ className, icon, text, variant, htmlContent }) => {
  const containerClass = `info-bg-${variant} mb-4 ${htmlContent ? '' : 'd-flex align-items-center'} ${className}`;

  return (
    <div className={containerClass}>
      {htmlContent ? (
        <div>{htmlContent}</div>
      ) : (
        <>
          <div className="mr-3">
            <IconGG icon={icon} />
          </div>
          <p>{text}</p>
        </>
      )}
    </div>
  );
};

InfoBox.defaultProps = {
  className: '',
  icon: 'info',
  text: '',
  variant: 'primary'
};

export default InfoBox;
