import React from 'react';
import { getIn, useFormikContext } from 'formik';
import { FormikSelect } from '../../Utils/Select';

const StatusSelect = ({ field, modelName, options = [], label, abbr = false }) => {
  const { setFieldValue, values, touched, errors } = useFormikContext();

  // const options = [
  //   { value: 'active', label: 'Activo' },
  //   { value: 'inactive', label: 'Inactivo' }
  // ];

  const status = getIn(values, field.name);

  return (
    <FormikSelect
      abbr={abbr}
      label={label}
      placeholder="Selecciona una opción"
      defaultValue={status}
      options={options}
      error={getIn(errors, field.name)}
      value={status}
      onChange={option => {
        setFieldValue(`${modelName}[translatedStatus]`, option.label || '');
        setFieldValue(field.name, option.value || '');
      }}
      touched={getIn(touched, field.name)}
    />
  );
};

export default StatusSelect;
