import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IconBtn } from '../Button';
import SidebarAccordion from './SidebarAccordion';

const AdminSidebarLinks = ({ isActive, handleCloseSidebar }) => {
  const { notifications = {} } = useSelector(state => state.utils);
  const { pendings: myPendingsCounter } = notifications;

  const zoneAccordionItems = [
    {
      itemTitle: 'Regiones',
      to: 'admin/regions'
    },
    {
      itemTitle: 'Provincias',
      to: 'admin/provinces'
    },
    {
      itemTitle: 'Comunas',
      to: 'admin/communes'
    }
  ];

  const configAccordionItems = [
    {
      itemTitle: 'General',
      to: 'admin/settings'
    },
    {
      itemTitle: 'Landing Corredor',
      to: 'admin/broker_landing_settings'
    },
    {
      itemTitle: 'Landing Cliente',
      to: 'admin/client_landing_settings'
    },
    {
      itemTitle: 'Blog',
      to: 'admin/blogs'
    }
  ];

  const parametersAccordionItems = [
    {
      itemTitle: 'Rubros',
      to: 'admin/industries'
    },
    {
      itemTitle: 'Aseguradoras',
      to: 'admin/insurance_companies'
    },
    {
      itemTitle: 'Ramos',
      to: 'admin/insurance_categories'
    },
    {
      itemTitle: 'Planes de pago',
      to: 'admin/payment_plans'
    },
    {
      itemTitle: 'IA',
      to: 'admin/ai_parameters'
    }
  ];

  return (
    <>
      <SidebarLink
        eventKey="home"
        icon="home-alt"
        optionTitle="Inicio"
        optionPath="/admin/home"
        onClick={handleCloseSidebar}
        isActivePath={isActive}
      />
      <SidebarLink
        eventKey="accounts"
        icon="briefcase"
        optionTitle="Cuentas"
        optionPath="/admin/accounts"
        onClick={handleCloseSidebar}
        isActivePath={isActive}
      />
      <SidebarLink
        eventKey="insurance_policies"
        icon="notes"
        optionTitle="Pólizas"
        optionPath="/admin/insurance_policies"
        onClick={handleCloseSidebar}
        isActivePath={isActive}
      />
      <SidebarLink
        eventKey="contracts"
        icon="notes"
        optionTitle="Contratos"
        optionPath="/admin/contracts"
        onClick={handleCloseSidebar}
        isActivePath={isActive}
      />
      <SidebarLink
        eventKey="renewals"
        icon="renewal-file"
        optionTitle="Renovaciones"
        optionPath="/admin/renewals"
        onClick={handleCloseSidebar}
        isActivePath={isActive}
      />
      <SidebarLink
        eventKey="leads"
        icon="trending"
        optionTitle="Ventas"
        optionPath="/admin/leads"
        onClick={handleCloseSidebar}
        isActivePath={isActive}
      />
      <SidebarLink
        eventKey="agenda"
        icon="play-list-add"
        optionTitle="Mi Agenda"
        badge={myPendingsCounter > 0}
        badgeItem={myPendingsCounter}
        optionPath="/admin/agenda"
        onClick={handleCloseSidebar}
        isActivePath={isActive}
      />
      <SidebarLink
        eventKey="premium_collections"
        icon="list-tree"
        optionTitle="Cobranza"
        optionPath="/admin/premium_collections"
        onClick={handleCloseSidebar}
        isActivePath={isActive}
      />
      <SidebarLink
        eventKey="commission_collections"
        icon="list-tree"
        optionTitle="Comisiones"
        optionPath="/admin/commission_collections"
        onClick={handleCloseSidebar}
        isActivePath={isActive}
      />
      <SidebarLink
        eventKey="reports"
        icon="document-type"
        optionTitle="Reportes"
        optionPath="/admin/reports"
        onClick={handleCloseSidebar}
        isActivePath={isActive}
      />
      <SidebarLink
        eventKey="bulk_upload"
        icon="file-document"
        optionTitle="Carga masiva"
        optionPath="/admin/bulk_upload"
        onClick={handleCloseSidebar}
        isActivePath={isActive}
      />
      <SidebarLink
        eventKey="companies"
        icon="organisation"
        optionTitle="Empresas"
        optionPath="/admin/companies"
        onClick={handleCloseSidebar}
        isActivePath={isActive}
      />
      <SidebarLink
        eventKey="users"
        icon="user-list"
        optionTitle="Usuarios"
        optionPath="/admin/users"
        onClick={handleCloseSidebar}
        isActivePath={isActive}
      />
      <SidebarAccordion
        accordionTitle="Parámetros"
        accordionIcon="database"
        handleCloseSidebar={handleCloseSidebar}
        isActive={isActive}
        accordionItems={parametersAccordionItems}
      />
      <SidebarAccordion
        accordionTitle="Zonas"
        accordionIcon="pin"
        handleCloseSidebar={handleCloseSidebar}
        isActive={isActive}
        accordionItems={zoneAccordionItems}
      />
      <SidebarAccordion
        accordionTitle="Configuraciones"
        accordionIcon="options"
        handleCloseSidebar={handleCloseSidebar}
        isActive={isActive}
        accordionItems={configAccordionItems}
      />
    </>
  );
};

const SidebarLink = ({ badge = false, badgeItem, icon, isActivePath, optionPath, optionTitle, ...props }) => (
  <Nav.Link as={Link} to={optionPath} active={isActivePath(optionPath)} {...props}>
    <span className="link-menu">
      <IconBtn icon={icon} variant="disabled" iconSize="sm" className="btn mr-4" />
      {optionTitle}
      {badge && <span className="link-menu--badge">{badgeItem}</span>}
    </span>
  </Nav.Link>
);

export default AdminSidebarLinks;
