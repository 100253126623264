import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { createAdminAccountRequest } from '../../../requests/admin/adminAccounts';
import { scrollToTop } from '../../../services/utils';
import basicAccount from '../../../screens/Account/basicAccount';
import AccountForm from './AccountForm';
import { createAccountRequest } from '../../../requests/accounts';

const AccountModalForm = ({
  insuranceBrokerId,
  handleCancelButton,
  setRefreshKey,
  fromAdmin,
  executiveManagerId = ''
}) => {
  const [account, setAccount] = useState(basicAccount);
  const dispatch = useDispatch();

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Cuenta creada con éxito' }));
    handleCancelButton();
    setRefreshKey(prevKey => prevKey + 1);
  };

  const getValues = values => {
    if (fromAdmin) {
      return {
        ...values,
        account: { ...values.account, executiveManagerId }
      };
    }

    return values;
  };

  const handleCreateRequest = (values, setSubmitting) => {
    const updatedValues = getValues(values);
    const sendParams = snakeCaseKeys(updatedValues);

    const createRequest = fromAdmin ? createAdminAccountRequest : createAccountRequest;
    createRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      callback: () => setSubmitting(false)
    });
  };

  const handleCustomParams = () => {
    if (insuranceBrokerId && fromAdmin) {
      setAccount({ ...account, insuranceBrokerId });
    }
  };

  useEffect(scrollToTop, []);
  useEffect(handleCustomParams, []);

  return (
    <div>
      <AccountForm
        action="new"
        account={account}
        formRequest={handleCreateRequest}
        handleCancelButton={handleCancelButton}
        fromAdmin={fromAdmin}
      />
    </div>
  );
};

export default AccountModalForm;
