import React, { useRef, useState } from 'react';

import BeneficiaryMovementDatatable from '../../../components/Beneficiary/BeneficiaryMovementTable';
import { IconBtn, SimpleCenteredModal } from '../../../components';
import { useFetchData } from '../../../hooks';
import { debounceIndexAdminBeneficiaryMovsRequest } from '../../../requests/admin/adminBeneficiaryMovements';

const MovementsModal = ({ insurancePolicyId, beneficiaryId, firstMovement = '' }) => {
  const modelUrl = '/admin/contracts';
  const [modalShow, setModalShow] = useState(false);
  const [modalSize, setModalSize] = useState('md');
  const [modalTitle, setModalTitle] = useState('');
  const customParamsRef = useRef({});

  const columns = [
    {
      name: 'Fecha',
      selector: 'date',
      cell: ({ createdAt }) => createdAt,
      sortable: false,
      grow: '1.25'
    },
    {
      name: 'movimiento',
      selector: 'movement_info',
      sortable: false,
      grow: '4',
      cell: ({ movementInfo }) => movementInfo
    }
  ];

  const {
    data: movements,
    totalData: totalMovements,
    moreData,
    setMoreData,
    isFetching,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminBeneficiaryMovsRequest,
    fetchingErrorMessage: 'Oops, hubo un problema al buscar los contratos. Por favor inténtalo nuevamente',
    withDataTable: true,
    parentId: insurancePolicyId,
    customParams: { beneficiary_id: beneficiaryId }
  });

  const handleShowCreateTaskModal = () => {
    setModalShow(true);
    setModalTitle('Movimientos');
    setModalSize('md');
  };

  const handleCloseModal = () => {
    setModalShow(false);
  };

  return (
    <>
      <div className="datatable-task-component--container">
        <span>{firstMovement}</span>
        <TaskIcon icon="play-list-add" onClick={handleShowCreateTaskModal} />
      </div>

      <SimpleCenteredModal
        title={modalTitle}
        show={modalShow}
        onHide={handleCloseModal}
        size={modalSize}
        body={
          <BeneficiaryMovementDatatable
            tableData={movements}
            tableDataAmount={totalMovements}
            modelUrl={modelUrl}
            isFetching={isFetching}
            moreData={moreData}
            setMoreData={setMoreData}
            handleIndexRequest={handleIndexRequest}
            customParamsRef={customParamsRef}
            columns={columns}
            userModule="admin"
            noSubHeader
          />
        }
      />
    </>
  );
};

const TaskIcon = ({ icon, isShow, onClick, text }) => {
  return (
    <IconBtn
      icon={icon}
      onClick={onClick}
      variant="transparent"
      className={`datatable-task-component--button btn black ${isShow ? 'show' : ''}`}
    >
      {text}
    </IconBtn>
  );
};

export default MovementsModal;
