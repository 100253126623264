import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { DataTableTextArea } from '../Input';
import { SimpleCenteredModal } from '../Modal';

const ReadMoreArray = ({ children, isEditable, item, less, lines, more, title, iconBtn, ...props }) => {
  const { preview = '' } = props;
  const [expanded, setExpanded] = useState(false);

  const toggleLines = event => {
    event.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <>
      <div className="text-center">
        <>
          {preview}
          <span>
            ...{' '}
            {!isEditable && (
              <a href="/#" className="text-primary" onClick={toggleLines}>
                {more}
              </a>
            )}
          </span>
        </>
      </div>
      <SimpleCenteredModal
        show={expanded}
        title={title}
        body={
          isEditable ? (
            <DataTableTextArea
              id={item.id}
              attribute={item.attribute}
              defaultValue={item.defaultValue}
              updateRequest={item.handleUpdateRequest}
              maxLength={1000}
              updateDataTable
              verticalResize
              rows={9}
            />
          ) : (
            <div className="small">{children}</div>
          )
        }
        onHide={() => setExpanded(false)}
      />
    </>
  );
};

ReadMoreArray.defaultProps = {
  lines: 2,
  more: 'Leer más',
  less: 'Leer menos'
};

ReadMoreArray.propTypes = {
  lines: PropTypes.number,
  less: PropTypes.string,
  more: PropTypes.string
};

export default ReadMoreArray;
