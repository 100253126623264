import React from 'react';
import { Form } from 'react-bootstrap';

const CheckBox = ({ text }) => (
  <Form.Group controlId="formBasicChecbox">
    <Form.Check type="checkbox" label={text} />
  </Form.Group>
);

export default CheckBox;
