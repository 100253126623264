import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const indexAdminAiParameterRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/admin/ai_parameters', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const showAdminAiParameterRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('get', `/admin/ai_parameters/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const createAdminAiParameterRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/admin/ai_parameters', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const updateAdminAiParameterRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('put', `/admin/ai_parameters/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback,
    failureCallback,
    source
  });

export const deleteAdminAiParameterRequest = (id, { dispatch, failureCallback, successCallback, callback, source }) =>
  ApiService.request('delete', `/admin/ai_parameters/${id}`, {
    dispatch,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const debounceIndexAdminAiParameterRequest = AwesomeDebouncePromise(indexAdminAiParameterRequest, 300);
