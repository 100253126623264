import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { SimpleCenteredModal, StatisticCard } from '../../../components';
import { ModalBodyIcon } from '../../../components/Shared';
import { bulkUploadBeneficiariesRequest } from '../../../requests/admin/bulkUploads';
import BeneficiariesAdditionForm from './Form/BeneficiariesAdditionForm';

const BeneficiariesAddition = ({ errorMessage, handleModalClose, handleModalOpen, ...props }) => {
  const { setErrorMessage, setModalBody, activeModal } = props;

  const [modalConfirmationBody, setModalConfirmationBody] = useState('');
  const [modalConfirmationShow, setModalConfirmationShow] = useState(false);
  const dispatch = useDispatch();

  const handleSuccessRequest = response => {
    setErrorMessage(response.data);
    if (response.data.errors.length > 0) return;

    dispatch(sendAlert({ kind: 'success', message: 'Incorporaciones agregadas con éxito' }));
    handleModalClose();
  };

  const handleDebtContractRequest = (values, setSubmitting) => {
    setModalConfirmationShow(false);
    bulkUploadBeneficiariesRequest({
      dispatch,
      params: snakeCaseKeys(values.beneficiary),
      successCallback: handleSuccessRequest,
      callback: () => setSubmitting(false)
    });
  };

  const handleModalConfirmation = (values, setSubmitting) => {
    setModalConfirmationShow(true);
    setModalConfirmationBody(
      <ModalBodyIcon
        icon="reorder"
        iconVariant="danger"
        iconClass="text-dark"
        submitVariant="strong-danger"
        content="¿Estás seguro de querer enviar las "
        highlightedText="incorporaciones?"
        highlightedVariant="white"
        subContent="Al enviar, se aplicarán los movimientos a la nómina actual y se guardará el registro"
        formRequest={() => handleDebtContractRequest(values, setSubmitting)}
        handleModalClose={() => {
          setModalConfirmationShow(false);
          setSubmitting(false);
        }}
      />
    );
  };

  const handleModalBody = () => (
    <BeneficiariesAdditionForm
      beneficiary={{ additions: '' }}
      formRequest={handleModalConfirmation}
      handleModalClose={handleModalClose}
      errorMessage={errorMessage}
    />
  );

  const handleOnClickBtn = () =>
    handleModalOpen({
      title: 'Incorporaciones nóminas',
      body: handleModalBody,
      size: 'xl',
      activeModalName: 'addition'
    });

  const handleUpdateModalBody = () => setModalBody(handleModalBody);

  // useEffect(handleUpdateModalBody, [errorMessage]);
  useEffect(() => {
    if (activeModal === 'addition') {
      handleUpdateModalBody();
    }
  }, [errorMessage, activeModal]);

  return (
    <Col sm={6} lg={4} className="mt-5">
      <StatisticCard
        icon="export"
        variant="primary-light"
        title="Incorporaciones nóminas"
        clickable
        onClick={handleOnClickBtn}
      />

      <SimpleCenteredModal
        show={modalConfirmationShow}
        body={modalConfirmationBody}
        onHide={() => null}
        closeButton={false}
      />
    </Col>
  );
};

export default BeneficiariesAddition;
