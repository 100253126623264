import React from 'react';
import { Table } from 'react-bootstrap';

const ContractItemsTable = ({ contractItems }) => {
  return (
    <Table size="sm" responsive borderless className="table__insurance-covers">
      <InsuranceCoverTableHeader />
      <InsuranceCoverTableBody contractItems={contractItems} />
    </Table>
  );
};

const InsuranceCoverTableHeader = () => (
  <thead>
    <tr>
      <th style={{ width: '10%' }}>N°</th>
      <th style={{ width: '20%' }}>Nombre</th>
      <th style={{ width: '20%' }}>Estado bien</th>
      <th style={{ width: '20%' }}>N° Póliza</th>
      <th style={{ width: '20%' }}>N° ítem</th>
      <th style={{ width: '30%' }}>Notas</th>
    </tr>
  </thead>
);

const InsuranceCoverTableBody = ({ contractItems }) => {
  return (
    <tbody>
      {contractItems.map((contractItem, index) => (
        <tr key={`insurance-cover-${index.toString()}`}>
          <td className="align-middle">{contractItem.itemNumber}</td>
          <td className="align-middle">{contractItem.name}</td>
          <td className="align-middle">{contractItem.translatedStatus}</td>
          <td className="align-middle">{contractItem.insurancePolicyNumber}</td>
          <td className="align-middle">{contractItem.insuranceItemNumber}</td>
          <td className="align-middle">
            {contractItem.notes.length > 0 ? contractItem.notes : 'Sin notas para este bien'}
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default ContractItemsTable;
