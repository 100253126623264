import React from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { SimpleCenteredModal } from '../../components';
import basicBeneficiary from './basicBeneficiary';
import { sendAlert } from '../../actions/utils';
import { createPolicyBeneficiaryRequest } from '../../requests/beneficiaries';
import AdminBeneficiaryForm from '../AdminScreens/Beneficiary/form/AdminBeneficiaryForm';

const BeneficiaryNew = ({ policyId, handleModalClose, modalShow, setMoreData }) => {
  const dispatch = useDispatch();

  const handleSuccessCreate = () => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Beneficiario creado con éxito' }));
    setMoreData(m => !m);
  };

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    createPolicyBeneficiaryRequest(policyId, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureCreate
    });
  };

  return (
    <SimpleCenteredModal
      title="Crear asegurado"
      body={
        <AdminBeneficiaryForm
          action="new"
          beneficiary={basicBeneficiary}
          show={modalShow}
          formRequest={handleCreateRequest}
          handleModalClose={handleModalClose}
        />
      }
      show={modalShow}
      onHide={handleModalClose}
      size="lg"
    />
  );
};

export default BeneficiaryNew;
