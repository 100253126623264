import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import BeneficiaryFields from './BeneficiaryFields';

const AdminBeneficiaryForm = ({
  action,
  errors,
  handleModalClose,
  isSubmitting,
  onHide,
  setFieldValue,
  touched,
  values
}) => {
  const handleCancelButton = () => {
    handleModalClose();
  };

  const submitBtnText = action === 'new' ? 'Crear asegurado' : 'Guardar Cambios';

  return (
    <Form>
      <BeneficiaryFields
        values={values}
        setFieldValue={setFieldValue}
        errors={errors}
        touched={touched}
        action={action}
      />
      <Row className="d-flex justify-content-end mt-5">
        <Col md={6} sm={12}>
          <div className="d-flex justify-content-end">
            <Button
              size="sm"
              className="w-30 no-shadow mr-5 rounded-border"
              variant="cancel"
              onClick={() => handleCancelButton()}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="submit"
              size="lg"
              onClick={onHide}
              className="w-90 no-shadow text-black-50 rounded-border"
              disabled={isSubmitting}
            >
              {submitBtnText}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ beneficiary }) => {
  return {
    beneficiary
  };
};

const validationSchema = Yup.object().shape({
  beneficiary: Yup.object().shape({
    name: Yup.string().required('Debes ingresar un nombre'),
    nationalIdentification: Yup.string()
      .required('Debes ingresar RUT')
      .rut('El RUT es inválido'),
    birthday: Yup.date()
      .required('Debes ingresar una fecha')
      .formatdate(),
    role: Yup.string().required('Debes ingresar un rol'),
    incorporationDate: Yup.date()
      .required('Debes ingresar una fecha de incorporación')
      .formatdate(),
    companySentDateIncorporation: Yup.date()
      .required('Debes ingresar una fecha')
      .formatdate(),
    dependentsNumber: Yup.number()
      .typeError('Debes escoger un número')
      .integer('Debe ser un numero entero')
      .required('Requerido')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest, onHide } = props;
  formRequest(values, setSubmitting);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(AdminBeneficiaryForm);
