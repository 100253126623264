import React from 'react';

const BrokerLandingReview = () => {
  return (
    <section id="Review" className="broker-landing__horizontal-info">
      <h4 className="broker-landing__horizontal-info__title landing-section-title text-dark-blue">
        Lo que opinan nuestros clientes
      </h4>
      <p>Slider por desarrollar</p>
      <div className="broker-landing__review__KindServices" />
    </section>
  );
};

export default BrokerLandingReview;
