import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Field, Form, getIn, withFormik } from 'formik';
import * as Yup from 'yup';

import { BasicTextArea } from '../../../../components';
import { InfoBox } from '../../../../components/Shared';

const BeneficiariesAdditionForm = ({ errors, setFieldValue, setSubmitting, isSubmitting, touched, ...props }) => {
  const { errorMessage, handleModalClose } = props;
  const commonClasses = 'bg-light-gray px-3 rounded-pill font-weight-bold';

  const handleColumnRules = ({
    rowArray: [
      policyNumber,
      nationalIdentif,
      name,
      birthday,
      role,
      incorpDate,
      companySentDateIncorp,
      dependNumber,
      lifeCov,
      healthCov,
      dentalCov,
      catastrophicCov,
      comments
    ]
  }) => {
    let allStructure = '';

    if (policyNumber) {
      const parsedPolicyNumber = policyNumber.trim();
      allStructure += `${parsedPolicyNumber}`;
    }

    if (nationalIdentif) {
      const parsedNationalIdentification = nationalIdentif.trim();
      allStructure += `\t${parsedNationalIdentification}`;
    }

    if (name) {
      const parsedName = name;
      allStructure += `\t${parsedName}`;
    }

    if (birthday) {
      const birthdayBase = birthday.replace(/[-]/g, '/');
      const parsedBirthday = birthdayBase.trim();
      allStructure += `\t${parsedBirthday}`;
    }

    if (role) {
      const parsedRole = role.trim();
      allStructure += `\t${parsedRole}`;
    }

    if (incorpDate) {
      const incorporationDate = incorpDate.replace(/[-]/g, '/');
      const parsedIncorporationDate = incorporationDate.trim();
      allStructure += `\t${parsedIncorporationDate}`;
    }

    if (companySentDateIncorp) {
      const companySentDateIncorporation = companySentDateIncorp.replace(/[-]/g, '/');
      const parsedcompanySentDateIncorporation = companySentDateIncorporation.trim();
      allStructure += `\t${parsedcompanySentDateIncorporation}`;
    }

    if (dependNumber) {
      const parsedDependentsNumber = parseInt(dependNumber, 0);
      allStructure += `\t${parsedDependentsNumber}`;
    }

    if (lifeCov) {
      const parsedLifeCoverage = parseInt(lifeCov, 0);
      allStructure += `\t${parsedLifeCoverage}`;
    }

    if (healthCov) {
      const parsedHealthCoverage = parseInt(healthCov, 0);
      allStructure += `\t${parsedHealthCoverage}`;
    }

    if (dentalCov) {
      const parsedDentalCoverage = parseInt(dentalCov, 0);
      allStructure += `\t${parsedDentalCoverage}`;
    }

    if (catastrophicCov) {
      const parsedCatastrophicCoverage = parseInt(catastrophicCov, 0);
      allStructure += `\t${parsedCatastrophicCoverage}`;
    }

    if (comments) {
      const parsedComments = comments;
      allStructure += `\t${parsedComments}`;
    }

    return allStructure;
  };

  const handleOnChangeTextArea = ({ fieldName, rows = [] }) => {
    const formattedComment = rows.map(row => {
      const rowArray = row.split('\t').filter(word => word);
      const formattedRows = handleColumnRules({ rowArray });

      const splitElement = row.split(';');
      const splitLength = splitElement.length >= 2;
      return splitLength ? `${splitElement[0].trim()}\t${splitElement[1].trim()}` : formattedRows;
    });

    setFieldValue(fieldName, formattedComment.join('\n'));
  };

  const reportMessage = () => {
    return (
      <>
        <p className="mb-1">Reporte:</p>

        {errorMessage.errors.map((message, index) => (
          <p key={`message-${index.toString()}`}>- {message}</p>
        ))}

        <p className="mt-1">Movimientos exitosos: {errorMessage?.success_items}</p>
        <p>Movimientos rechazados: {errorMessage?.failure_items}</p>
      </>
    );
  };

  return (
    <>
      {errorMessage && (
        <>
          <InfoBox icon="danger" variant="danger" htmlContent={reportMessage()} />
        </>
      )}

      <p>Para cargar el listado de incorporaciones:</p>
      <ul>
        <li className="ml-4">
          Ingresa una incorporación por línea con los siguientes datos: N° de póliza; RUT titular; Nombre titular; Fecha
          de nacimiento titular (dd/mm/aaaa); Rol titular; Fecha de incorporación (dd/mm/aaaa); Fecha de envío a
          compañía - incorporación (dd/mm/aaaa); Número de cargas; Cobertura Vida (1/0); Cobertura Salud (1/0);
          Cobertura Dental (1/0); Cobertura Catastrófico (1/0); Comentarios
        </li>
        <li className="ml-4">
          Copia los datos desde un documento <span className={commonClasses}>XLSX</span> o separa cada dato de la póliza
          utlizando <span className={commonClasses}>;</span>
        </li>
      </ul>

      <Form>
        <Row>
          <Col xs={12}>
            <Field name="beneficiary[additions]">
              {({ field }) => (
                <BasicTextArea
                  {...field}
                  row={6}
                  placeholder={`N° de póliza; RUT titular; Nombre titular; Fecha de nacimiento titular\n...`}
                  onChange={({ target }) => {
                    const targetValue = target.value;
                    const rows = targetValue.split('\n');
                    handleOnChangeTextArea({ fieldName: field.name, rows });
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  className="p-3 textarea-tab-size"
                />
              )}
            </Field>
          </Col>
        </Row>

        <Row className="mt-2 d-flex justify-content-end">
          <Col md={7}>
            <Row>
              <Col md={6} className="mt-4">
                <Button block variant="cancel" className="no-shadow" onClick={handleModalClose}>
                  Cancelar
                </Button>
              </Col>
              <Col md={6} className="mt-4">
                <Button block type="submit" variant="submit" className="no-shadow" disabled={isSubmitting}>
                  Cargar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const setInitialValues = ({ beneficiary }) => ({ beneficiary });

const validationSchema = Yup.object().shape({
  beneficiary: Yup.object().shape({
    additions: Yup.string().required('Debes ingresar los datos de las incorporaciones')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest, onHide } = props;
  formRequest(values, setSubmitting);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(BeneficiariesAdditionForm);
