import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import moment from 'moment';

import { DatesFilter, StatisticCard } from '../../../components';
import {
  exportAccountReportRequest,
  exportCommissionCollectionReportRequest,
  exportContractSummaryRequest,
  exportLeadSummaryRequest,
  exportCollectionReportRequest,
  exportProductionReportRequest,
  exportProposalReportRequest,
  exportBeneficiaryReportRequest,
  exportCertificateReportRequest,
  exportContractsReportRequest,
  exportContractItemsReportRequest
} from '../../../requests/admin/reports';
import { downloadFile } from '../../../services/utils';

const AdminReportIndex = () => {
  const dispatch = useDispatch();

  const beginningOfMonth = new Date(moment().startOf('month'));
  const endOfMonth = new Date(moment().endOf('month'));

  const [customParams, setCustomParams] = useState({ exportDateFrom: beginningOfMonth, exportDateTo: endOfMonth });

  const handleDateRangeValues = ({ ignoreDates = false, filterName, xlsxName, ...extraProps }) => {
    if (ignoreDates) return { ignoreDates, filterName, xlsxName, ...extraProps };

    const { exportDateFrom, exportDateTo } = customParams;
    const name = `export_${filterName}`;

    return { filterName, xlsxName, [`${name}_from`]: exportDateFrom, [`${name}_to`]: exportDateTo, ...extraProps };
  };

  const handleContractSummaryRequest = ({ filterName, xlsxName, ...extraProps }) => {
    const sendParams = handleDateRangeValues({ filterName, xlsxName, ...extraProps });
    exportContractSummaryRequest({
      dispatch,
      params: snakeCaseKeys(sendParams),
      successCallback: response => downloadFile(response)
    });
  };

  const handleLeadSummaryRequest = ({ filterName, xlsxName, ...extraProps }) => {
    const sendParams = handleDateRangeValues({ filterName, xlsxName, ...extraProps });
    exportLeadSummaryRequest({
      dispatch,
      params: snakeCaseKeys(sendParams),
      successCallback: response => downloadFile(response)
    });
  };

  const handleProductionReportRequest = ({ filterName, xlsxName, ...extraProps }) => {
    const sendParams = handleDateRangeValues({ filterName, xlsxName, ...extraProps });
    exportProductionReportRequest({
      dispatch,
      params: snakeCaseKeys(sendParams),
      successCallback: response => downloadFile(response)
    });
  };

  const handleProposalReportRequest = ({ ignoreDates, filterName, xlsxName, ...extraProps }) => {
    const sendParams = handleDateRangeValues({ ignoreDates, filterName, xlsxName, ...extraProps });
    exportProposalReportRequest({
      dispatch,
      params: snakeCaseKeys(sendParams),
      successCallback: response => downloadFile(response)
    });
  };

  const handleAccountReportRequest = ({ filterName, xlsxName, ...extraProps }) => {
    const sendParams = { xlsxName, ...extraProps };
    exportAccountReportRequest({
      dispatch,
      params: snakeCaseKeys(sendParams),
      successCallback: response => downloadFile(response)
    });
  };

  const handleCollectionReportRequest = ({ ignoreDates, filterName, xlsxName, ...extraProps }) => {
    const sendParams = handleDateRangeValues({ ignoreDates, filterName, xlsxName, ...extraProps });
    exportCollectionReportRequest({
      dispatch,
      params: snakeCaseKeys(sendParams),
      successCallback: response => downloadFile(response)
    });
  };

  const handleCommissionCollectionReportRequest = ({ ignoreDates, filterName, xlsxName, ...extraProps }) => {
    const sendParams = handleDateRangeValues({ ignoreDates, filterName, xlsxName, ...extraProps });
    exportCommissionCollectionReportRequest({
      dispatch,
      params: snakeCaseKeys(sendParams),
      successCallback: response => downloadFile(response)
    });
  };

  const handleBeneficiaryReportRequest = ({ ignoreDates, filterName, xlsxName, ...extraProps }) => {
    const sendParams = handleDateRangeValues({ ignoreDates, filterName, xlsxName, ...extraProps });
    exportBeneficiaryReportRequest({
      dispatch,
      params: snakeCaseKeys(sendParams),
      successCallback: response => downloadFile(response)
    });
  };

  const handleCertificateReportRequest = ({ ignoreDates, filterName, xlsxName, ...extraProps }) => {
    const sendParams = handleDateRangeValues({ ignoreDates, filterName, xlsxName, ...extraProps });
    exportCertificateReportRequest({
      dispatch,
      params: snakeCaseKeys(sendParams),
      successCallback: response => downloadFile(response)
    });
  };

  const handleContractsReportRequest = ({ ignoreDates, filterName, xlsxName, ...extraProps }) => {
    const sendParams = handleDateRangeValues({ ignoreDates, filterName, xlsxName, ...extraProps });
    exportContractsReportRequest({
      dispatch,
      params: snakeCaseKeys(sendParams),
      successCallback: response => downloadFile(response)
    });
  };

  const handleContractItemsReportRequest = ({ ignoreDates, filterName, xlsxName, ...extraProps }) => {
    const sendParams = handleDateRangeValues({ ignoreDates, filterName, xlsxName, ...extraProps });
    exportContractItemsReportRequest({
      dispatch,
      params: snakeCaseKeys(sendParams),
      successCallback: response => downloadFile(response)
    });
  };

  return (
    <Row className="mb-5">
      <Col lg={7} className="mt-4 pt-2">
        <h5 className="text-primary-dark-blue">Reportes</h5>
      </Col>
      <Col lg={5} className="d-lg-flex justify-content-lg-end pt-4">
        <DatesFilter
          startDateField="exportDateFrom"
          endDateField="exportDateTo"
          customParams={customParams}
          setCustomParams={setCustomParams}
        />
      </Col>
      <Col sm={6} lg={4} className="mt-5">
        <StatisticCard
          icon="document-type-thin"
          variant="primary-light"
          title="Producción"
          subtitle="(fecha de creación de póliza, ejecución de endoso)"
          clickable
          onClick={() =>
            handleProductionReportRequest({
              filterName: 'created_at',
              xlsxName: 'reporte_de_produccion_por_creacion'
            })
          }
        />
      </Col>
      <Col sm={6} lg={4} className="mt-5">
        <StatisticCard
          icon="document-type-thin"
          variant="primary-light"
          title="Producción"
          subtitle="(fecha de emisión de póliza, emisión de endoso)"
          clickable
          onClick={() =>
            handleProductionReportRequest({
              filterName: 'issue_date',
              xlsxName: 'reporte_de_produccion_por_emision'
            })
          }
        />
      </Col>
      <Col sm={6} lg={4} className="mt-5">
        <StatisticCard
          icon="document-type-thin"
          variant="primary-light"
          title="Pólizas"
          subtitle="(fecha de creación)"
          clickable
          onClick={() =>
            handleContractSummaryRequest({
              filterName: 'created_at',
              xlsxName: 'reporte_de_polizas_por_creacion',
              filterSelect: ['policy', 'created_at']
            })
          }
        />
      </Col>
      <Col sm={6} lg={4} className="mt-5">
        <StatisticCard
          icon="document-type-thin"
          variant="primary-light"
          title="Pólizas"
          subtitle="(fecha de emisión)"
          clickable
          onClick={() =>
            handleContractSummaryRequest({
              filterName: 'issue_date',
              xlsxName: 'reporte_de_polizas_por_emision',
              filterSelect: ['policy', 'issue_date']
            })
          }
        />
      </Col>
      <Col sm={6} lg={4} className="mt-5">
        <StatisticCard
          icon="document-type-thin"
          variant="primary-light"
          title="Propuestas"
          clickable
          onClick={() =>
            handleProposalReportRequest({
              ignoreDates: true,
              xlsxName: 'reporte_de_propuestas'
            })
          }
        />
      </Col>
      <Col sm={6} lg={4} className="mt-5">
        <StatisticCard
          icon="document-type-thin"
          variant="primary-light"
          title="Ventas"
          subtitle="(fecha de creación)"
          clickable
          onClick={() =>
            handleLeadSummaryRequest({
              renewals: false,
              filterName: 'created_at',
              xlsxName: 'reporte_de_ventas',
              filterSelect: ['lead', 'created_at']
            })
          }
        />
      </Col>
      <Col sm={6} lg={4} className="mt-5">
        <StatisticCard
          icon="document-type-thin"
          variant="primary-light"
          title="Renovaciones"
          subtitle="(fecha de término de póliza origen)"
          clickable
          onClick={() =>
            handleLeadSummaryRequest({
              renewals: true,
              filterName: 'renewal_date',
              xlsxName: 'reporte_de_renovaciones'
            })
          }
        />
      </Col>
      <Col sm={6} lg={4} className="mt-5">
        <StatisticCard
          icon="document-type-thin"
          variant="primary-light"
          title="Cuentas"
          clickable
          onClick={() =>
            handleAccountReportRequest({
              xlsxName: 'reporte_de_cuentas'
            })
          }
        />
      </Col>
      <Col sm={6} lg={4} className="mt-5">
        <StatisticCard
          icon="document-type-thin"
          variant="primary-light"
          title="Cobranza"
          clickable
          onClick={() =>
            handleCollectionReportRequest({
              ignoreDates: true,
              xlsxName: 'reporte_de_cobranzas'
            })
          }
        />
      </Col>
      <Col sm={6} lg={4} className="mt-5">
        <StatisticCard
          icon="document-type-thin"
          variant="primary-light"
          title="Comisiones"
          clickable
          onClick={() =>
            handleCommissionCollectionReportRequest({
              ignoreDates: true,
              xlsxName: 'reporte_de_comisiones'
            })
          }
        />
      </Col>
      <Col sm={6} lg={4} className="mt-5">
        <StatisticCard
          icon="document-type-thin"
          variant="primary-light"
          title="Nóminas"
          clickable
          onClick={() =>
            handleBeneficiaryReportRequest({
              ignoreDates: true,
              xlsxName: 'reporte_de_nominas'
            })
          }
        />
      </Col>
      <Col sm={6} lg={4} className="mt-5">
        <StatisticCard
          icon="document-type-thin"
          variant="primary-light"
          title="Certificados"
          subtitle="(Fecha de emisión)"
          clickable
          onClick={() =>
            handleCertificateReportRequest({
              filterName: 'issue_date',
              xlsxName: 'reporte_de_certificados'
            })
          }
        />
      </Col>
      <Col sm={6} lg={4} className="mt-5">
        <StatisticCard
          icon="document-type-thin"
          variant="primary-light"
          title="Contratos"
          subtitle="(Fecha de inicio de vigencia)"
          clickable
          onClick={() =>
            handleContractsReportRequest({
              filterName: 'validity_start',
              xlsxName: 'reporte_de_contratos'
            })
          }
        />
      </Col>
      <Col sm={6} lg={4} className="mt-5">
        <StatisticCard
          icon="document-type-thin"
          variant="primary-light"
          title="Bienes"
          subtitle="(Fecha de inicio de vigencia)"
          clickable
          onClick={() =>
            handleContractItemsReportRequest({
              filterName: 'validity_start',
              xlsxName: 'reporte_de_bienes'
            })
          }
        />
      </Col>
    </Row>
  );
};

export default AdminReportIndex;
