import ApiService from '../../services/apiService';

export const exportContractSummaryRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) => {
  ApiService.request('get', `/admin/reports/contract_summary.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    failureCallback,
    callback,
    source
  });
};

export const exportLeadSummaryRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) => {
  ApiService.request('get', `/admin/reports/lead_summary.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    failureCallback,
    callback,
    source
  });
};

export const exportProductionReportRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) => {
  ApiService.request('get', `/admin/reports/production_report.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    failureCallback,
    callback,
    source
  });
};

export const exportProposalReportRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) => {
  ApiService.request('get', `/admin/reports/proposal_report.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    failureCallback,
    callback,
    source
  });
};

export const exportAccountReportRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) => {
  ApiService.request('get', `/admin/reports/account_report.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    failureCallback,
    callback,
    source
  });
};

export const exportCollectionReportRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) => {
  ApiService.request('get', `/admin/reports/collection_report.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    failureCallback,
    callback,
    source
  });
};

export const exportCommissionCollectionReportRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) => {
  ApiService.request('get', `/admin/reports/commission_collection_report.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    failureCallback,
    callback,
    source
  });
};

export const exportBeneficiaryReportRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) => {
  ApiService.request('get', `/admin/reports/beneficiary_report.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    failureCallback,
    callback,
    source
  });
};

export const exportCertificateReportRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) => {
  ApiService.request('get', `/admin/reports/certificate_report.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    failureCallback,
    callback,
    source
  });
};

export const exportContractsReportRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) => {
  ApiService.request('get', `/admin/reports/contracts_report.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    failureCallback,
    callback,
    source
  });
};

export const exportContractItemsReportRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) => {
  ApiService.request('get', `/admin/reports/contract_items_report.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    failureCallback,
    callback,
    source
  });
};
