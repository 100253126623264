import * as Yup from 'yup';
import { addDaysToDate } from '../../../services/utils';

const baseContractValidationSchema = {
  accountId: Yup.string().required('Debes seleccionar una cuenta'),
  contractNumber: Yup.string().required('Debes ingresar un número de contrato'),
  validityEnd: Yup.date()
    .notRequired()
    .formatdate()
    .when('validityStart', (validityStart, schema) => {
      const limitDate = addDaysToDate(validityStart, 1);
      return validityStart && schema.min(limitDate, 'Debe ser mayor al inicio de vigencia');
    }),
  validityStart: Yup.date()
    .required('Debes ingresar un inicio de vigencia')
    .formatdate()
    .when('validityEnd', (validityEnd, schema) => {
      const limitDate = addDaysToDate(validityEnd, -1);
      return validityEnd && schema.max(limitDate, 'Debe ser menor al término de vigencia');
    }),
  contractItemsAttributes: Yup.array().of(
    Yup.object().shape({
      itemNumber: Yup.string().required('Debes ingresar un número de bien'),
      name: Yup.string()
        .required('Debes ingresar un nombre de bien')
        .max(160, 'Máx. 160 caracteres'),
      notes: Yup.string().max(1000, 'Máx. 1000 caracteres'),
      status: Yup.string().required('Debes seleccionar un estado')
    })
  )
};
export const contractSchema = userModule => {
  const parsedSchema =
    userModule === 'admin'
      ? {
          ...baseContractValidationSchema,
          insuranceBrokerId: Yup.string().required('Debes ingresar un corredor')
        }
      : baseContractValidationSchema;

  return Yup.object().shape({
    insuranceContract: Yup.object().shape(parsedSchema, [['validityEnd', 'validityStart']])
  });
};

export const firstStepFields = ['accountId', 'contractNumber', 'validityStart', 'validityEnd'];

export const secondStepFields = ['contractItemsAttributes'];
