import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexAiParameterRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/ai_parameters', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const debounceIndexAiParameterRequest = AwesomeDebouncePromise(indexAiParameterRequest, 300);
