import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import { emptyKeys, textUpperCase } from '../../../services/utils';
import { BasicTextArea, FormikInput } from '../../Utils/Input';
import { NestedAttributes } from '../../Utils/NestedAttributes';
import { AccordionCard } from '../../Utils/Cards';
import basicContractItem from './basicContractItem';
import { InsurancePolicyRemoteSelector } from '../../Shared';
import InsuranceItemRemoteSelector from '../../Shared/InsuranceItemRemoteSelector';
import StatusSelect from './StatusSelect';

const ContractItems = ({ modelName, userModule }) => {
  const { setFieldValue, values } = useFormikContext();
  const { contractItemsAttributes, insuranceBrokerCompanyId } = getIn(values, modelName);

  const currentInsuranceItems = contractItemsAttributes.filter(insuranceItem => !insuranceItem._destroy);
  const lastInsuranceItemObject = currentInsuranceItems[currentInsuranceItems.length - 1];
  const lastInsuranceItem = emptyKeys(lastInsuranceItemObject, ['id', 'name', 'itemNumber']);

  const mapResults = contractItemsAttributes.map((body, index) => {
    if (body._destroy) return undefined;

    return (
      <Col key={`item-${index.toString()}`}>
        <AccordionCard
          title={`Bien ${body.itemNumber}`}
          length={null}
          customClassName="with-overflow"
          body={
            <InsuranceItemForm
              key={`item-${index.toString()}`}
              modelName={`${modelName}[contractItemsAttributes][${index}]`}
              index={index}
              userModule={userModule}
              insuranceBrokerCompanyId={insuranceBrokerCompanyId}
            />
          }
        />
      </Col>
    );
  });

  return (
    <NestedAttributes
      bottomBtnAddRow
      splitLine={false}
      mapInputs={mapResults}
      arrayValues={contractItemsAttributes}
      setFieldValue={setFieldValue}
      valuePath={`${modelName}[contractItemsAttributes]`}
      newAttributes={{ ...basicContractItem, ...lastInsuranceItem }}
      btnMessage="Agregar bien"
      removeBtnStyle={{ position: 'absolute', top: '7.5rem', right: '2rem' }}
    />
  );
};

export const InsuranceItemForm = ({ modelName, index, userModule }) => {
  const { errors, touched, setFieldValue, values } = useFormikContext();
  const [selectedPolicy, setSelectedPolicy] = useState(null);

  const statusOptions = [
    { value: 'active', label: 'Activo' },
    { value: 'inactive', label: 'No activo' }
  ];

  const itemNumberTooltip = () => {
    return `Ingresa el número de bien o similar que te permite identificar el bien.<br/> 
    Si no hay un número específico, puedes mantener el listado 1, 2, 3…`;
  };

  const insurancePolicyTooltip = () => {
    return `Asocia el Bien a la Póliza que lo cubre. Puedes hacerlo aquí para cada Bien de forma individual,<br/>
    o luego en la vista de tabla del módulo Contratos de forma masiva.`;
  };

  const insuranceItemTooltip = () => {
    return `Asocia el Bien a un Ítem de la Póliza que lo cubre. Hazlo solo si la coincidencia es exacta,<br/> 
    es decir, el Bien del Contrato y el Ítem de la Póliza ser refieren exactamente a lo mismo.`;
  };

  useEffect(() => {
    if (!getIn(values, `${modelName}[itemNumber]`)) {
      setFieldValue(`${modelName}[itemNumber]`, index + 1); // Opcional: +1 para iniciar desde 1
    }
  }, [index, modelName, setFieldValue, values]);

  const policySelectorParams = () => {
    const paramsMap = {
      // admin: { filter_broker_company_id: insuranceBrokerCompanyId },
      admin: { display_length: 200 },
      broker: { from_company: true }
    };
    return paramsMap[userModule];
  };

  return (
    <>
      <Row>
        <Col xs={12} md={4} lg={3}>
          <Field name={`${modelName}[itemNumber]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                placeholder="0000"
                label="N° de Bien"
                onChange={input => setFieldValue(field.name, textUpperCase(input.target.value))}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                tooltipText={itemNumberTooltip()}
              />
            )}
          </Field>
        </Col>
        <Col xs={12} md={4} lg={6}>
          <Field name={`${modelName}[name]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre Bien"
                maxLength={160}
                placeholder="Nombre Bien"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                margin="custom-width"
              />
            )}
          </Field>
        </Col>

        <Col xs={12} md={4} lg={3}>
          <Field name={`${modelName}[status]`}>
            {({ field }) => (
              <StatusSelect abbr field={field} modelName={modelName} options={statusOptions} label="Estado bien" />
            )}
          </Field>
        </Col>

        <Col xs={12} className="mb-4">
          <Field name={`${modelName}[notes]`}>
            {({ field }) => (
              <BasicTextArea
                {...field}
                label="Notas"
                placeholder="Escribe todas las notas necesarias del Bien"
                maxLength={1000}
                rows={2}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                className="p-3"
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xs={12} md={4}>
          <Field name={`${modelName}[insurancePolicyId]`}>
            {({ field }) => (
              <InsurancePolicyRemoteSelector
                label="N° de póliza asociada"
                field={field}
                modelName={modelName}
                tooltipText={insurancePolicyTooltip()}
                userModule={userModule}
                initialParams={policySelectorParams()}
                setExtraSelectedOption={setSelectedPolicy}
              />
            )}
          </Field>
        </Col>
        <Col xs={12} md={4}>
          <Field name={`${modelName}[insuranceItemId]`}>
            {({ field }) => (
              <InsuranceItemRemoteSelector
                label="N° de ítem en póliza asociada"
                field={field}
                modelName={modelName}
                tooltipText={insuranceItemTooltip()}
                userModule={userModule}
              />
            )}
          </Field>
        </Col>
        <Col xs={12} md={12}>
          {selectedPolicy && (
            <>
              <p>Aseguradora: {selectedPolicy.insurance_company_name} </p>
              <p>Corredor: {selectedPolicy.insurance_broker_company_name} </p>
              <p>Ramo: {selectedPolicy.insurance_category_name}</p>
              <p>Inicio de vigencia póliza: {selectedPolicy.validity_start}</p>
              <p>Término de vigencia póliza: {selectedPolicy.validity_end}</p>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ContractItems;
