import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { FormikInput } from '../../../components';
// import { requestSignIn } from '../../../actions/auth';
import { updateAbility } from '../../../config/ability';
import { AbilityContext } from '../../../config/abilityContext';

const Login = ({ onHide, isValid, errors, touched, signedIn, sentEmail, setFieldValue, ...props }) => {
  const { sendCodeRequest, values, setSubmitting } = props;
  const ability = useAbility(AbilityContext);

  const handleOnLogin = () => {
    if (!signedIn) return;
    updateAbility(ability);
  };

  useEffect(handleOnLogin, [signedIn]);

  return (
    <div className="login-box--container">
      <div className="login-box">
        <h6 className="login-box--title text-color-white text-center">Ingresa a tu cuenta</h6>
        <Form className="login-box--form">
          {sentEmail ? (
            <Field name="verificationCode">
              {({ field }) => (
                <FormikInput
                  {...field}
                  noLabelFocus
                  label="Código de verificación"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          ) : (
            <>
              <Field name="email">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    noLabelFocus
                    label="Correo electrónico"
                    placeholder="usuario@correo.com"
                    inputType="email"
                    error={errors[field.name]}
                    touched={touched[field.name]}
                  />
                )}
              </Field>
              <Field name="password">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    noLabelFocus
                    inputType="password"
                    label="Contraseña"
                    placeholder="**********"
                    error={errors[field.name]}
                    touched={touched[field.name]}
                  />
                )}
              </Field>
            </>
          )}

          <Button
            variant="secondary"
            size="lg"
            className="my-5 w-100 btn-big"
            block
            type="submit"
            disabled={!isValid}
            onClick={onHide}
          >
            Inicia sesión
          </Button>

          {sentEmail && (
            <Button
              block
              variant="transparent"
              className="white btn-big"
              onClick={() => {
                setFieldValue('verificationCode', '');
                sendCodeRequest(values, setSubmitting);
              }}
            >
              reenviar código de verificación
            </Button>
          )}
        </Form>

        {!sentEmail && (
          <Link className="login-box--forgot-password-link" to="/recover_password">
            Olvidé mi contraseña
          </Link>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest, signedIn } = state.auth;
  return {
    ongoingRequest,
    signedIn
  };
};

const initialValues = {
  email: '',
  password: '',
  verificationCode: ''
};

const validationSchema = ({ sentEmail }) => {
  return Yup.object().shape({
    email: Yup.string()
      .email('El email que ingresaste no es válido')
      .required('Debes ingresar un email'),
    password: sentEmail ? Yup.string().nullable() : Yup.string().required('Debes ingresar una contraseña'),
    verificationCode: sentEmail
      ? Yup.string().required('Debes ingresar un código de verificación')
      : Yup.string().nullable()
  });
};

// const handleSubmit = (values, { props }) => {
//   const { dispatch } = props;

//   const successCallback = (response) => {
//     console.log("wow: ", response)
//   }
//   dispatch(
//     requestSignIn(
//       {
//         user: {
//           email: values.email,
//           password: values.password
//         }
//       },
//       props.fromLocation,
//       successCallback
//     )
//   );
// };

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(Login)
  )
);
