import React, { useState } from 'react';
// import ReactMarkdown from 'react-markdown';
// import remarkGfm from 'remark-gfm';
import { Button, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { checkAdminThreadAi, compareAdminLeadQuotations } from '../../../requests/admin/lead';
import { ModalBodyIcon } from '../../Shared';
import { sendAlert } from '../../../actions/utils';
import { BasicTextArea } from '../../Utils/Input';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import testResponse from './testResponse';
import ResultAiTable from './ResultAiTable';
import { generatePrompt } from './prompts';
import IconGG from '../../Utils/Icon';
import AiParameterSelect from './AiParameterSelect';

const CompareQuotationsModal = ({ handleModalClose, lead, selectedQuotations }) => {
  const variant = 'submit';
  const iconVariant = 'success';
  const icon = 'check-o';
  const dispatch = useDispatch();
  const [resultAi, setResultAi] = useState('');
  const [loading, setLoading] = useState(false);
  const [additionalInstruction, setAdditionalInstruction] = useState('');
  const RETRY_DELAY = 10000;
  const enableButtonTest = false;
  const { compareAiPrompt, insuranceCategoryId, insuranceBrokerCompanyId, insuranceCategoryName } = lead;
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedAiParameter, setSelectedAiParameter] = useState('');

  const submitButtonText = () => {
    return loading ? (
      <>
        Comparando
        <span role="img" aria-label="sparkles">
          ✨
        </span>
        <Spinner animation="border" variant="primary" className="ml-2" />
      </>
    ) : (
      'Comparar ✨'
    );
  };

  const handleCompletedStatus = message => {
    const jsonMatch = message.match(/```json\n([\s\S]*?)\n```/);
    setLoading(false);

    if (jsonMatch) {
      try {
        const jsonObject = JSON.parse(jsonMatch[1]);
        const parsedObject = camelCaseEmptyStringRecursive(jsonObject);
        setResultAi(parsedObject);
      } catch (error) {
        dispatch(sendAlert({ kind: 'error', message: 'Error al parsear JSON' }));
      }
    }
  };

  const handleFailedStatus = message => {
    setResultAi(message);
    setLoading(false);
    // dispatch(sendAlert({ kind: 'error', message }));
    setErrorMessage('Ocurrió un error. Por favor intenta nuevamente.');
  };

  const handleCheckThread = ({ theadId, runId, attempt = 1 }) => {
    const endpointRequest = checkAdminThreadAi;
    const sendParams = { thread_id: theadId, run_id: runId };
    endpointRequest({
      dispatch,
      formData: true,
      params: sendParams,
      successCallback: response => {
        const { message, status } = response.data;

        if (status === 'completed') {
          handleCompletedStatus(message);
        } else if (status === 'failed') {
          handleFailedStatus(message);
        } else {
          setTimeout(() => {
            handleCheckThread({ theadId, runId, attempt: attempt + 1 });
          }, RETRY_DELAY);
        }
      }
    });
  };

  const handleCompareSuccessRequest = response => {
    if (!response.data) {
      setErrorMessage('Ocurrió un error. Por favor intenta nuevamente.');
      setLoading(false);
      return;
    }
    const { thread_id: theadId, run_id: runId } = response.data;
    setTimeout(() => {
      handleCheckThread({ theadId, runId });
    }, RETRY_DELAY);
  };

  const completePrompt = () => {
    return generatePrompt({
      selectedQuotations,
      additionalInstruction,
      compareAiPrompt: selectedAiParameter?.compare_ai_params || compareAiPrompt
    });
  };

  const handleCompareLeadQuotations = () => {
    setLoading(true);
    const endpointRequest = compareAdminLeadQuotations;
    const prompt = completePrompt();
    const sendParams = { prompt, quotations_ids: selectedQuotations.map(quotation => quotation.id) };
    // const sendParams = { prompt, quotations: selectedQuotations }

    endpointRequest(lead.id, {
      dispatch,
      formData: true,
      params: sendParams,
      successCallback: response => handleCompareSuccessRequest(response),
      failureCallback: () => {
        setErrorMessage('Ocurrió un error. Por favor intenta nuevamente.');
        setLoading(false);
      }
    });
  };

  const testCompleteFields = () => {
    const jsonMatch = testResponse.match(/```json\n([\s\S]*?)\n```/);
    // setResultAi(message);
    setLoading(false);
    dispatch(sendAlert({ kind: 'success', message: 'Respuesta generada con éxito' }));
    if (jsonMatch) {
      try {
        const jsonObject = JSON.parse(jsonMatch[1]);
        const parsedObject = camelCaseEmptyStringRecursive(jsonObject);
        setResultAi(parsedObject);
      } catch (error) {
        dispatch(sendAlert({ kind: 'error', message: 'Error al parsear JSON' }));
      }
    }
  };

  const generateColumns = title => [
    { columnName: title, columnStyle: { width: '200px' }, selector: 'Variable' },
    ...selectedQuotations.map(item => ({
      columnName: item.insuranceCompanyName,
      columnStyle: { width: '200px' },
      selector: item.insuranceCompanyName
    }))
  ];

  return (
    <>
      <ModalBodyIcon
        icon={icon}
        size="xl"
        iconVariant={iconVariant}
        content="Comparar ✨"
        confirmText={false}
        subContent={
          <>
            <Row>
              {!resultAi && (
                <>
                  {errorMessage && (
                    <Col md={12} className="d-flex align-items-center justify-content-center">
                      <Alert variant="danger" className="d-flex align-items-center justify-content-center">
                        <IconGG className="mr-2" icon="danger" size="sm" />
                        <p className="detail font-weight-bold m-0">{errorMessage}</p>
                      </Alert>
                    </Col>
                  )}

                  <Col md={12}>
                    <p>
                      Estás haciendo una comparación de cotizaciones del ramo {`"${insuranceCategoryName}"`}. Elige el
                      listado de parámetros que quieres usar para la comparación.
                    </p>

                    <AiParameterSelect
                      selectedAiParameter={selectedAiParameter}
                      setSelectedAiParameter={setSelectedAiParameter}
                      insuranceCategoryId={insuranceCategoryId}
                      companyId={insuranceBrokerCompanyId}
                    />
                  </Col>

                  {selectedAiParameter && (
                    <Col md={12}>
                      <p>Puntos a comparar:</p>
                      <p style={{ whiteSpace: 'pre-wrap' }}>{selectedAiParameter.compare_ai_params}</p>
                    </Col>
                  )}

                  {!selectedAiParameter && compareAiPrompt && (
                    <Col md={12}>
                      <p>Puntos a comparar por defecto:</p>
                      <p style={{ whiteSpace: 'pre-wrap' }}>{compareAiPrompt}</p>
                    </Col>
                  )}

                  <Col md={12}>
                    <BasicTextArea
                      label="Instrucción adicional"
                      onChange={e => {
                        setAdditionalInstruction(e.target.value);
                      }}
                      placeholder="Instrucción adicional"
                      value={additionalInstruction}
                      row={3}
                    />
                  </Col>
                </>
              )}
            </Row>

            {resultAi && (
              <div>
                {resultAi.map(section => (
                  <div key={section.title}>
                    <ResultAiTable columns={generateColumns(section.title)} tableBody={section.result} />
                  </div>
                ))}
              </div>
            )}
          </>
        }
        formContent={
          <Row className="justify-content-center">
            <Col md={6} className="mt-4">
              <Button block variant="cancel" className="no-shadow" onClick={handleModalClose}>
                Cancelar
              </Button>
            </Col>
            {enableButtonTest && (
              <Col md={6} className="mt-4">
                <Button
                  block
                  type="submit"
                  variant={variant}
                  className="no-shadow"
                  onClick={testCompleteFields}
                  disabled={loading}
                >
                  TEST
                </Button>
              </Col>
            )}
            <Col md={6} className="mt-4">
              <Button
                block
                type="submit"
                variant={variant}
                className="no-shadow"
                onClick={handleCompareLeadQuotations}
                disabled={loading || (!selectedAiParameter?.compare_ai_params && !compareAiPrompt)}
              >
                {submitButtonText()}
              </Button>
            </Col>
          </Row>
        }
        highlightedText=""
        highlightedVariant="white"
        handleModalClose={handleModalClose}
        submitVariant={variant}
      />
    </>
  );
};

export default CompareQuotationsModal;
