import { handleDateFormat } from '../../../services/utils';
import infoCardElement from '../../Utils/Cards/InfoCardMethods';

const generalObject = ({ contract }) => {
  const {
    accountId,
    accountName,
    comments,
    contractNumber,
    description,
    executiveContract,
    insuranceBrokerCompanyName,
    insuranceBrokerName,
    signatureDate,
    translatedStatus,
    validityEnd,
    validityStart
  } = contract;

  const object = {};

  object.accountName = infoCardElement({
    link: true,
    key: 'Cuenta',
    value: accountName,
    url: `/admin/accounts/${accountId}`
  });
  object.contractNumber = infoCardElement({
    string: true,
    key: 'N° contrato',
    value: contractNumber
  });
  object.status = infoCardElement({
    string: true,
    key: 'Estado contrato',
    value: translatedStatus || 'Sin definir'
  });
  if (description)
    object.description = infoCardElement({
      string: true,
      key: 'Descripción',
      value: description
    });
  if (signatureDate)
    object.signatureDate = infoCardElement({
      string: true,
      key: 'Fecha de firma',
      value: handleDateFormat(signatureDate)
    });
  object.validityStart = infoCardElement({
    string: true,
    key: 'Inicio de vigencia',
    value: handleDateFormat(validityStart)
  });
  object.validityEnd = infoCardElement({
    string: true,
    key: 'Término de vigencia',
    value: validityEnd ? handleDateFormat(validityEnd) : 'Sin fecha de término'
  });

  if (executiveContract)
    object.executiveContract = infoCardElement({
      string: true,
      key: 'Ejecutivo contrato',
      value: executiveContract
    });
  object.insuranceBroker = infoCardElement({
    string: true,
    key: 'Corredor',
    value: `${insuranceBrokerCompanyName}/${insuranceBrokerName}`
  });

  if (comments)
    object.comments = infoCardElement({
      string: true,
      key: 'Comentarios',
      value: comments
    });

  return object;
};

export default generalObject;
