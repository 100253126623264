import { handleDateFormat } from '../../../services/utils';
import infoCardElement from '../../Utils/Cards/InfoCardMethods';

const generalObject = ({ contractItem, userModule }) => {
  const { insuranceContractInfo = {} } = contractItem;

  const {
    accountId,
    accountName,
    accountNationalIdentification,
    translatedStatus,
    validityEnd,
    validityStart,
    contractNumber,
    description,
    executiveContract,
    signatureDate
  } = insuranceContractInfo;

  const object = {};

  object.accountName = infoCardElement({
    link: true,
    key: 'Cuenta',
    value: accountName,
    url: userModule === 'admin' ? `/admin/accounts/${accountId}` : `/accounts/${accountId}`
  });
  object.accountNationalIdentification = infoCardElement({
    string: true,
    key: 'RUT Cuenta',
    value: accountNationalIdentification
  });
  object.contractNumber = infoCardElement({
    string: true,
    key: 'N° contrato',
    value: contractNumber
  });
  if (description)
    object.description = infoCardElement({
      string: true,
      key: 'Descripción',
      value: description
    });
  object.status = infoCardElement({
    string: true,
    key: 'Estado contrato',
    value: translatedStatus || 'Sin definir'
  });

  if (signatureDate)
    object.signatureDate = infoCardElement({
      string: true,
      key: 'Fecha de firma',
      value: handleDateFormat(signatureDate)
    });
  object.validityStart = infoCardElement({
    string: true,
    key: 'Inicio de vigencia del contrato',
    value: handleDateFormat(validityStart)
  });
  object.validityEnd = infoCardElement({
    string: true,
    key: 'Término de vigencia del contrato',
    value: validityEnd ? handleDateFormat(validityEnd) : 'Sin fecha de término'
  });
  if (executiveContract)
    object.executiveContract = infoCardElement({
      string: true,
      key: 'Ejecutivo contrato',
      value: executiveContract
    });

  return object;
};

export default generalObject;
