import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns, IconBtn } from '../../../components';

const columns = memoize(
  ({
    handleBasicActions,
    modelUrl,
    withShow = false,
    withEdit = false,
    withDestroy = false,
    showClick = false,
    editClick = false
  }) => {
    return [
      {
        name: 'Nombre',
        selector: 'name',
        sortable: true,
        grow: '2'
      },
      {
        name: 'Compañía',
        selector: 'companyName',
        sortable: true,
        grow: '2'
      },
      {
        name: 'Ramo',
        selector: 'insuranceCategoryName',
        sortable: true,
        grow: '2'
      },
      {
        name: 'Activo',
        selector: 'active',
        sortable: true,
        center: true,
        grow: '2',
        cell: ({ active }) => (
          <>
            {active ? (
              <IconBtn icon="check" iconSize="xs" variant="submit" noBtn className="no-shadow" />
            ) : (
              <IconBtn icon="close" iconSize="xs" variant="danger" noBtn className="no-shadow" />
            )}
          </>
        )
      },
      {
        cell: item => (
          <BasicActionBtns
            item={item}
            clickHandler={handleBasicActions}
            path={modelUrl}
            withShow={withShow}
            withEdit={withEdit}
            withDestroy={withDestroy}
            showClick={showClick}
            editClick={editClick}
          />
        ),
        ignoreRowClick: true,
        allowOverflow: false,
        button: true,
        grow: '1',
        minWidth: '150px'
      }
    ];
  }
);

export default columns;
