import React from 'react';
import { getIn, useFormikContext } from 'formik';
import { useFetchForRemoteSelect } from '../../../hooks';
import { InputRemoteSelect } from '../../../components';
import { debounceIndexAdminCompaniesRequest, indexAdminCompanyRequest } from '../../../requests/admin/companies';

const CompanyRemoteSelect = ({ field, abbr, disabled, modelName }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();

  const { options, selectedOption, fetchOptions } = useFetchForRemoteSelect({
    indexRequest: indexAdminCompanyRequest,
    debouncedIndexRequest: debounceIndexAdminCompaniesRequest,
    value: getIn(values, `${modelName}[companyId]`)
  });

  return (
    <InputRemoteSelect
      {...field}
      abbr={abbr}
      disabled={disabled}
      isClearable
      label="Empresa"
      placeholder="Seleccionar Empresa"
      defaultOptions={options}
      value={selectedOption}
      request={fetchOptions}
      onChange={data => {
        setFieldValue(field.name, data?.value || '');
      }}
      error={getIn(errors, field.name)}
      touched={getIn(touched, field.name)}
    />
  );
};

export default CompanyRemoteSelect;
