import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import AdminAiParameterForm from './AdminAiParameterForm';
import basicAiParameter from './basicAiParameter';
import { showAdminAiParameterRequest, updateAdminAiParameterRequest } from '../../../requests/admin/adminAiParameters';

const AdminAiParameterEdit = ({ match }) => {
  const { id } = match.params;
  const modelUrl = '/admin/ai_parameters';
  const [aiParameter, setAiParameter] = useState(basicAiParameter);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Parámetros IA', href: modelUrl },
    { key: 2, name: 'Editar parámetro IA' }
  ];

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Parámetro IA actualizado con éxito' }));
    history.push(modelUrl);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleUpdateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    updateAdminAiParameterRequest(id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const fetchInsuranceCategory = () => {
    showAdminAiParameterRequest(id, {
      dispatch,
      params: { edit: true },
      successCallback: response => setAiParameter(camelCaseRecursive(response.data)),
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchInsuranceCategory, []);

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={<AdminAiParameterForm action="edit" aiParameter={aiParameter} formRequest={handleUpdateRequest} />}
    />
  );
};

export default AdminAiParameterEdit;
