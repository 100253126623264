import React from 'react';

import { FormikSelect } from '../../Utils/Select';

const AiParameterSelect = ({ selectedInsuranceType, disabled, ...props }) => {
  const { selectedAiParameter, setSelectedAiParameter, options } = props;

  return (
    <FormikSelect
      abbr
      label="Parámetro IA"
      placeholder="Parámetro IA"
      options={options}
      value={selectedAiParameter}
      isDisabled={disabled}
      onChange={option => {
        setSelectedAiParameter(option);
      }}
    />
  );
};
export default AiParameterSelect;
