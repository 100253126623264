import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import {
  BasicTextArea,
  BtnTooltip,
  FormikInput,
  IconBtn,
  InfoCard,
  SimpleCenteredModal,
  UploadFile
} from '../../../components';
import DropzoneFileSection from '../../../components/Shared/DropzoneFileSection';
import { textUpperCase } from '../../../services/utils';
import PolicyInfoSection from './PolicyInfoSection';
import ValidateContentAiModal from '../../../components/InsurancePolicies/ValidateContentAi/ValidateContentAiModal';
import useAiParameters from './useAiParameters';

const InsurancePolicyFormThirdTab = ({
  isProposal,
  modelName,
  temporalDropzoneFiles,
  setTemporalDropzoneFiles,
  disableNicoCode
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const { values, setFieldValue } = useFormikContext();
  const insurancePolicy = getIn(values, modelName);
  const { additionalDocumentsInfo } = insurancePolicy;

  const {
    comments,
    adminComments,
    contract,
    contractInfo,
    insuranceCategoryName,
    insuranceCategoryId,
    insuranceBrokerCompanyId,
    validateAiPrompt
  } = values.insurancePolicy;

  const { aiParameters, validateAiParamsCount } = useAiParameters(insuranceCategoryId, insuranceBrokerCompanyId);

  const updateComments = () => {
    setFieldValue(`${modelName}[comments]`, `${comments}`);
    setFieldValue(`${modelName}[adminComments]`, `${adminComments}`);
  };

  const handleModalClose = () => {
    setModalShow(false);
    setModalBody('');
  };

  const handleValidateContentAiModal = () => {
    setModalShow(true);
    setModalBody(
      <ValidateContentAiModal
        handleModalClose={handleModalClose}
        policyFile={contract}
        attachmentId={contractInfo?.id}
        insuranceCategoryName={insuranceCategoryName}
        insuranceCategoryId={insuranceCategoryId}
        companyId={insuranceBrokerCompanyId}
        validateAiPrompt={validateAiPrompt}
        modelName={modelName}
        options={aiParameters}
      />
    );
  };

  const disabledValidateAiButton = () => {
    return (validateAiParamsCount === 0 && !validateAiPrompt) || !contract || !contractInfo;
  };

  useEffect(updateComments, []);

  return (
    <>
      <PolicyInfoSection isProposal={isProposal} modelName={modelName} disableNicoCode={disableNicoCode} />

      <hr className="w-100 mb-5" />

      <section className="form-section mb-5 w-100">
        <p className="section-title">Documentos</p>
        {isProposal ? (
          <Row>
            <AttachFile
              abbr
              label="Cotización"
              attribute="quotation"
              modelName={modelName}
              fileAccept="application/pdf"
            />
            <AttachFile
              abbr
              label="Aprobación"
              attribute="acceptance"
              modelName={modelName}
              fileAccept="image/*, application/pdf"
            />
          </Row>
        ) : (
          <Row>
            <Col sm={12} md={10} lg={10}>
              <Row>
                <AttachFile
                  label="Propuesta"
                  attribute="contractProposal"
                  modelName={modelName}
                  fileAccept="application/pdf"
                />
                <AttachFile
                  abbr
                  label="Póliza"
                  attribute="contract"
                  modelName={modelName}
                  fileAccept="image/*, application/pdf"
                />
              </Row>
            </Col>

            <Col sm={12} md={2} lg={2} className="d-flex align-items-center">
              <IconBtn className="mt-lg-0" onClick={handleValidateContentAiModal} disabled={disabledValidateAiButton()}>
                Validar contenido
                <span role="img" aria-label="sparkles">
                  ✨
                </span>
              </IconBtn>
            </Col>
          </Row>
        )}

        <DropzoneFileSection
          multiple
          label="Documentos adicionales"
          modelName="insurancePolicy"
          originalObject={insurancePolicy}
          persistedFiles={additionalDocumentsInfo}
          temporalDropzoneFiles={temporalDropzoneFiles}
          setTemporalDropzoneFiles={setTemporalDropzoneFiles}
        />

        <Row>
          <Col md={12}>
            <Field name={`${modelName}[additionalClauses]`}>
              {({ field }) => (
                <BasicTextArea
                  {...field}
                  label="Cláusulas adicionales"
                  placeholder="Ingresa cláusulas adicionales..."
                  row={3}
                  className="p-3"
                />
              )}
            </Field>
          </Col>
        </Row>

        {isProposal && <CreateContractFromProposal modelName={modelName} />}

        <Row className="mt-5">
          <Col md={12}>
            <Field name={`${modelName}[comments]`}>
              {({ field }) => (
                <BasicTextArea
                  {...field}
                  label="Comentario"
                  placeholder="Ingresa un comentario..."
                  row={4}
                  className="p-3"
                />
              )}
            </Field>
          </Col>
        </Row>
      </section>

      <SimpleCenteredModal size="xl" show={modalShow} body={modalBody} onHide={handleModalClose} />
    </>
  );
};

const AttachFile = ({ abbr, attribute, disabled, fileAccept, label, modelName }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();

  const savedFile = getIn(values, `${modelName}[${attribute}Info]`);
  const newFile = getIn(values, `${modelName}[${attribute}]`);

  const handleFileUrl = file => {
    if (!file) return '';
    const blob = new Blob([file], { type: file.type });
    return URL.createObjectURL(blob);
  };

  return (
    <Col lg={6} className="mb-3">
      <Field name={`${modelName}[${attribute}]`}>
        {({ field }) => (
          <UploadFile
            {...field}
            abbr={abbr}
            fileAccept={fileAccept}
            label={label}
            filename={newFile?.name || savedFile?.filename}
            fileUrl={savedFile?.fileUrl || handleFileUrl(newFile)}
            onChange={file => setFieldValue(field.name, file)}
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
            disabled={disabled}
          />
        )}
      </Field>
    </Col>
  );
};

const CreateContractFromProposal = ({ modelName }) => {
  const { errors, setFieldTouched, setFieldValue, touched, values } = useFormikContext();
  const { contractFile, contractNumber } = getIn(values, modelName);
  const cleaned = !contractNumber && !contractFile;

  const handleCleanContractInputs = () => {
    setFieldValue(`${modelName}[contractNumber]`, '');
    setFieldTouched(`${modelName}[contractNumber]`, true);
    setFieldValue(`${modelName}[contractFile]`, undefined);
    setFieldTouched(`${modelName}[contractFile]`, true);
  };

  return (
    <InfoCard title="Creación póliza" className="bg-light-gray bg-children-inherit mt-5">
      <p>Si la póliza ya fue emitida, puedes cargarla aquí y crear la propuesta y la póliza en un solo paso.</p>
      <p>Recuerda siempre revisar que los datos de la póliza representen lo solicitado en la propuesta.</p>

      <Row className="mt-4">
        <Col lg={3} xl={3}>
          <Field name={`${modelName}[contractNumber]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr={contractFile}
                placeholder="0000"
                label="Número de póliza"
                onChange={input => setFieldValue(field.name, textUpperCase(input.target.value))}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <AttachFile
          abbr={contractNumber}
          label="Póliza"
          attribute="contractFile"
          modelName={modelName}
          fileAccept="image/*, application/pdf"
        />
        <Col lg={3} xl={2} className="d-flex align-items-center">
          <BtnTooltip
            icon="undo"
            iconSize="sm"
            variant="transparent"
            tooltipText="Deshacer"
            onClick={handleCleanContractInputs}
            disabled={cleaned}
          />
        </Col>
      </Row>
    </InfoCard>
  );
};

export default InsurancePolicyFormThirdTab;
