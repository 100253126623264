import React from 'react';
import { getIn, useFormikContext } from 'formik';
import { useFetchForRemoteSelect } from '../../../hooks';
import {
  debounceIndexInsuranceCategoryRequest,
  indexInsuranceCategoryRequest
} from '../../../requests/insuranceCategories';
import { InputRemoteSelect } from '../../../components';

const InsuranceCategoryRemoteSelector = ({ field, modelName, disabled = false, abbr }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();

  const {
    options: insuranceCategories,
    selectedOption: selectedInsuranceCategory,
    fetchOptions: fetchInsuranceCategories
  } = useFetchForRemoteSelect({
    indexRequest: indexInsuranceCategoryRequest,
    debouncedIndexRequest: debounceIndexInsuranceCategoryRequest,
    value: getIn(values, `${modelName}[insuranceCategoryId]`),
    initialParams: {
      for_selector: true
    }
  });

  return (
    <InputRemoteSelect
      {...field}
      abbr={abbr}
      disabled={disabled}
      isClearable
      label="Ramo"
      placeholder="Seleccionar Ramo"
      defaultOptions={insuranceCategories}
      value={selectedInsuranceCategory}
      request={fetchInsuranceCategories}
      onChange={data => {
        setFieldValue(`${modelName}[insuranceCategoryName]`, data?.label || '');
        setFieldValue(field.name, data?.value || '');
      }}
      error={getIn(errors, field.name)}
      touched={getIn(touched, field.name)}
    />
  );
};

export default InsuranceCategoryRemoteSelector;
